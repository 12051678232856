import React from 'react';

import { getClickType } from '../../../sharedModules/getClickType';
import { updateImageSize } from '../../modules/getDealImage/updateImageSize';
import type { Deal } from '../../types/Deal';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import styles from './styles/deal-widget-image.css';

interface DealWidgetImageProps {
  deal: Deal;
}

const DealWidgetImage: React.FC<DealWidgetImageProps> = ({ deal }): JSX.Element | null => (
  <Consumer>
    {(value): JSX.Element | null => {
      const {
        webpImage,
        image,
        showFallback,
        productName,
        star,
        excludeFrom,
        articleUrl,
        genericSharedComponents: { AffiliateLink, LazyImage },
      } = value;
      const imageUrl = deal?.image ?? image;

      if (imageUrl) {
        const getClassName = (): string => {
          if (excludeFrom) {
            return 'inactive';
          }
          if (styles[showFallback]) {
            return showFallback;
          }
          return 'container';
        };
        const className = getClassName();
        const alt = deal?.model || productName || 'Image';

        return (
          <div className={styles[className]}>
            <AffiliateLink
              deal={deal}
              className={star === 'hero' ? 'deal-widget-hero' : 'deal-widget'}
              clickType={getClickType({
                link: deal.offer.link,
                articleUrl,
                productType: deal.product_type,
              })}
            >
              <LazyImage
                source={<source type="image/webp" srcSet={webpImage} />}
                {...{
                  src: updateImageSize(imageUrl, 200),
                  alt,
                  className: showFallback === 'editors-choice' ? 'editors-choice' : 'deal-widget',
                  width: 140,
                  height: star === 'hero' ? 120 : 160,
                }}
              />
            </AffiliateLink>
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default DealWidgetImage;
